.App {
    text-align: center;
  }
  .AppHeader {
    min-height: 25vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  .AppBody {
    padding: 10px;
    min-height: 65vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .AppFooter {
    min-height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .logo
  {
    width: 100%;
    max-width: 800px;
  }
  .form
  {
    border-radius: 25px;
    padding: 10px;
  }