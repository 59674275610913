.App {
    text-align: center;
  }
  
  .FixItIcon {
    /*
    animation: App-logo-spin infinite 20s linear;
    animation-play-state: paused;*/
    height: 15vmin;
    pointer-events: none;
  }
  
  .AppHeader {
    background-color: #535b69;
    min-height: 25vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  .AppBody {
    background-color: #535b69;
    min-height: 65vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .AppFooter {
    background-color: #535b69;
    min-height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .fixItButton
  {
    border-radius: 25px;
    padding: 10px;
    border: solid;
    color: white;
    text-decoration: none;
  }
  
  .AppLink {
    color: #61dafb;
  }
  
  .fixItButton:hover {
    border-width: 5px;
    color: rgb(0, 162, 255);
    background-color: #393f49;
    cursor: pointer;
  
  }
  /*
  .fixItButton:hover .FixItIcon {
    animation: App-logo-spin infinite 20s linear;
  }
  */
  .logo
  {
    height: 15vmin;
  }
  /*
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }*/
  
  a:hover{
    cursor: pointer;
    color: rgb(0, 162, 255);
  }

  .Body
  {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
  }
/*
  table, th {
    border: 1px solid black;
    color: white;
  }

  td {
    border: 1px solid black;
  }*/
  tbody > tr:hover
  {
    background-color: lightgrey;
    cursor: pointer;
  }
/*
  .alertTable
  {
    max-width: 100%;
  }*/